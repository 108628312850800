<template>
  <el-card>
    <div slot="header" class="card-header">
      <h3 style="margin: 0">User Page List</h3>
      <div>
        <el-button  size="large" type="primary" @click="getRegisterUser">Register User</el-button>
        <el-select v-model="em_id"  placeholder="Select  Employee" filterable>
          <el-option label="All" :value="0"></el-option>
          <el-option v-for="employee in employees" :key="employee.id" :value="employee.id" :label="employee.name"></el-option>
        </el-select>
      </div>
    </div>
    <div class="controls">
      <div class="control">
        <p>Items per page: </p>
        <el-select v-model="items">
          <el-option :label="5" :value="5"></el-option>
          <el-option :label="10" :value="10"></el-option>
          <el-option :label="25" :value="25"></el-option>
          <el-option :label="50" :value="50"></el-option>
          <el-option :label="100" :value="100"></el-option>
        </el-select>
      </div>
      <div class="control">
        <el-button-group>
          <el-button type="primary" icon="el-icon-arrow-left" @click="prevPage()" :disabled="!hasPreviousPage">Previous Page</el-button>
          <el-button type="primary" @click="nextPage()" :disabled="!hasNextPage">Next Page<i class="el-icon-arrow-right el-icon-right"></i></el-button>
        </el-button-group>
      </div>
    </div>
    <el-dialog title="Edit User Group" :visible.sync="editUserGroupDialog">
      <el-form ref="editusergroupForm" :model="editusergroupForm" label-width="70px">
        <el-form-item label="User Group">
          <el-select v-model="editusergroupForm.suag_id" placeholder="Select User Group" filterable>
            <el-option v-for="user_group in user_groups" :key="user_group.suag_id" :value="user_group.suag_id" :label="user_group.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Status">
          <el-select v-model="editusergroupForm.is_force_disabled" placeholder="Status">
            <el-option label="Enabled" :value="'0'"></el-option>
            <el-option label="Disabled" :value="'1'"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Remarks">
          <el-input v-model="editusergroupForm.remarks_force_disabled" :disabled="editusergroupForm.is_force_disabled === '0'"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editUserGroupDialog = false">Cancel</el-button>
        <el-button type="primary" @click="updateUserGroup">Confirm</el-button>
      </span>
    </el-dialog>
    <el-table :data="data" border max-height="580">
      <el-table-column label="ID" prop="id"></el-table-column>
      <el-table-column label="ID NO" prop="em_id"></el-table-column>
      <el-table-column label="USERNAME" prop="username"></el-table-column>
      <el-table-column label="NAME" prop="em_id_name"></el-table-column>
      <el-table-column label="DEPARTMENT" prop="bd_id_name"></el-table-column>
      <el-table-column label="POSITION" prop="bp_id_name"></el-table-column>
      <el-table-column label="GROUP ACCESS" prop="system_user_access_group_name"></el-table-column>
      <el-table-column label="ACTIONS" fixed="right" align="center" width="100">
        <template slot-scope="props">
          <el-button-group>
            <el-button type="success" icon="el-icon-edit" size="mini" @click="editUserGroup(props.row)"></el-button>
          </el-button-group>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<script>
export default {
  name: 'UserPageList',
  data () {
    return {
      data: [],
      user_groups: [],
      employees: [],
      em_id: 0,
      items: 5,
      page: 1,
      hasNextPage: false,
      hasPreviousPage: false,
      dialogAccessModule: false,
      editUserGroupDialog: false,
      editusergroupForm: {
        id: null,
        suag_id: null,
        is_force_disabled: null,
        remarks_force_disabled: null
      }
    }
  },
  watch: {
    items () {
      this.getUserList()
    },
    em_id () {
      this.getUserList()
    }
  },
  mounted () {
    this.getUserList()
    this.getUserGroup()
    this.getEmployees()
  },
  methods: {
    getEmployees () {
      this.$http
        .get('get.employee-list/0')
        .then(res => {
          this.employees = res.data.body
        })
    },
    getRegisterUser () {
      this.$http
        .get('sysadmin.systemaccess/register-user')
        .then(res => {
          this.$message({
            message: 'User Registration successfully updated',
            type: 'success'
          })
          this.getUserList()
        })
    },
    getUserGroup () {
      this.$http
        .get('sysadmin.systemaccess/group-list')
        .then(res => {
          this.user_groups = res.data.StatusCode ? res.data.body : []
        })
    },
    getUserList () {
      this.$http
        .get(`sysadmin.systemaccess/user/${this.em_id}/${this.items}?page=${this.page}`)
        .then(res => {
          this.data = res.data.StatusCode ? res.data.body.data : []
          this.hasNextPage = res.data.StatusCode ? !!res.data.body.next_page_url : false
          this.hasPreviousPage = res.data.StatusCode ? !!res.data.body.prev_page_url : false
        })
    },
    nextPage () {
      this.page++
      this.getUserList()
    },
    prevPage () {
      this.page--
      this.getUserList()
    },
    editUserGroup (row) {
      this.editUserGroupDialog = true
      this.editusergroupForm.id = row.id
      this.editusergroupForm.suag_id = row.suag_id
      this.editusergroupForm.is_force_disabled = row.is_force_disabled
      this.editusergroupForm.remarks_force_disabled = row.remarks_force_disabled
    },
    updateUserGroup () {
      this.editUserGroupDialog = false
      this.$http
        .post('sysadmin.systemaccess/user-group-update', this.editusergroupForm)
        .then(() => {
          this.$message({
            message: 'User Group Updated',
            type: 'success'
          })
          this.getUserList()
        })
        .catch(() => {
          this.$message({
            type: 'danger',
            message: 'Uh-oh! Something went wrong.'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .el-button {
    margin-right: 1rem;
  }
</style>
